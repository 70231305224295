var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PracticePlayPage',{style:({ '--dialogScale': _vm.scale }),attrs:{"image":_vm.category.image,"title":_vm.category.name[_vm.$i18n.locale],"description":_vm.category.description[_vm.$i18n.locale]}},[_c('v-row',{attrs:{"justify":"center","id":"attach"}},[_vm._l((_vm.languages),function(l){return _c('v-col',{key:l.locale,staticClass:"ma-3 mb-10",attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"card",attrs:{"shaped":"","elevation":"8"}},[_c('div',{staticClass:"card__imageBox",style:({
            background: `linear-gradient(45deg, ${l.gradientColor1}, ${l.gradientColor2})`
          })},[_c('v-img',{attrs:{"src":_vm.calculatedImageId(l.locale)}})],1),_c('v-card-title',[_vm._v(" "+_vm._s(l.title)+" ")]),_c('div',{staticClass:"card__actionBox"},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.showPrayer(l)}}},[_vm._v(" "+_vm._s(_vm.$t('pray'))+" ")]),_c('span',{staticClass:"ml-4 primary--text"},[_vm._v(_vm._s(l.name))])],1)],1)],1)}),(_vm.selectedLang)?_c('v-dialog',{style:({ transform: `scale(${_vm.scale})` }),attrs:{"fullsceen":"","overlay-opacity":"1","max-width":"800","persistent":"","attach":"#attach"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"shaped":""}},[_c('v-row',{staticClass:"card__imageBox",style:({
            background: `linear-gradient(45deg, ${_vm.selectedLang.gradientColor1}, ${_vm.selectedLang.gradientColor2})`
          }),attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"sm":"7","cols":"12"}},[(_vm.playVideo)?_c('Player',{key:'prayer' + _vm.selectedLang.locale,ref:'playerRef-' + _vm.selectedLang.locale,attrs:{"media":{ type: 'youtube', playerId: '7PrUU2JVjTg' },"emit":['ended', 'ready'],"options":{
                autoplay: false,
                displayDuration: true,
                noCookie: false,
                rel: 0,
                showinfo: 0,
                iv_load_policy: 3,
                modestbranding: 1,
                muted: false,
                settings: ['captions'],
                captions: { active: true }
              }},on:{"ready":function($event){return _vm.playPrayerVideo('playerRef-' + _vm.selectedLang.locale)},"ended":function($event){_vm.playVideo = false}}}):_c('div',[_c('v-img',{class:{ clippedImage: _vm.$vuetify.breakpoint.smAndUp },attrs:{"src":_vm.calculatedImageId(_vm.selectedLang.locale)}})],1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"text-h4 text-center mt-2 mb-2 pl-5 pr-5 d-flex justify-center align-center card__title",class:{
              'card__title-indian':
                _vm.selectedLang.locale == 'gu' || _vm.selectedLang.locale == 'hi'
            },style:({ textShadow: `1px 1px ${_vm.selectedLang.gradientColor1}` }),attrs:{"sm":"5","cols":"12"}},[_vm._v(" "+_vm._s(_vm.selectedLang.title)+" ")]):_vm._e(),(!_vm.playVideo)?_c('v-icon',{staticClass:"card__prayerVideo",attrs:{"x-large":""},on:{"click":function($event){_vm.playVideo = true}}},[_vm._v("mdi-play-circle")]):_vm._e()],1),(!_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"text-h5 text-center mt-2 mb-2",style:({ textShadow: `1px 1px ${_vm.selectedLang.gradientColor1}` })},[_vm._v(" "+_vm._s(_vm.selectedLang.title)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"mt-5 text-h6 text-sm-h5",class:{
            prayerText: _vm.$vuetify.breakpoint.smAndUp,
            prayerTextMobile: !_vm.$vuetify.breakpoint.smAndUp
          }},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$i18n.messages[_vm.selectedLang.locale]['prayerText'])}})]),_c('v-card-actions',[_c('v-spacer'),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"mr-3"},[_c('v-icon',{staticClass:"mr-3",on:{"click":function($event){return _vm.scaleUp()}}},[_vm._v("mdi-magnify-plus-outline")]),_c('v-icon',{on:{"click":function($event){return _vm.scaleDown()}}},[_vm._v("mdi-magnify-minus-outline")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog('playerRef-' + _vm.selectedLang.locale)}}},[_vm._v(" Close ")])],1)],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }